import request from '@/core/services/api.services.request'

export function getAllReturnproducts(params) {
  return request({
    url: '/return/product/list',
    method: 'get',
    params: params
  })
}
export function getOrderList(customer_id) {    
  return request({
    url: '/retrun/order/list/'+customer_id,
    method: 'get'  
  })
}

export function getOrderDetails(order_id) {
    return request({
      url: '/retrun/order/details/'+order_id,
      method: 'get',
    })
  }

  export function searchOrder(keyword) {
    return request({
      url: '/order/search/' + keyword,
      method: 'get',
    })
  }

  export function getOrder() {
    return request({
      url: '/order/getOrderlist',
      method: 'get',
    })
  }

  export function getReturnProduct(return_id) {
    return request({
      url: '/return/view/'+return_id,
      method: 'get',
    })
  }

  export function downloadPdf(id,type) {
    return request({
      url: '/pdf/create/' + id +'/' + type,
      method: 'get',
      responseType: 'blob',
    })
  }


